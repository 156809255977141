<style>
/* @media only screen and (orientation:portrait){
  body {
    height: 100vw;
    transform: rotate(90deg);
  }
} */
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import btnComponent from "@/components/btnComponent";
/**
 * Advanced-table component
 */
export default {
  page: {
    title: "โฟลว์สล็อต",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    btnComponent,
  },
  data() {
    return {
      title: "โฟลว์สล็อต",
      items: [
        {
          text: "บริการ",
        },
        {
          text: "โฟลว์สล็อต",
          active: true,
        },
      ],

      // update status
      optionsStatusFslot: [
        {
          repairStatusID: "COUT",
          nameTh: "Clock OUT",
        },
        {
          repairStatusID: "SW",
          nameTh: "เริ่มล้าง",
        },
        {
          repairStatusID: "FW",
          nameTh: "ล้างเสร็จ",
        },
      ],
      linkPtintFlowslot: "google.co.th",
      repairStatus: [],
      itemVMP: "",
      svIdP: "",
      repairIdP: "",
      totalPriceRO: 0,
      // end update status

      rowsService: [],
      loading: undefined,
      form: {},
      slotIdPut: "",
      branchId: "",
      branchIdLC: "",
      DateSearch: "",
      rowSlot: [],
      rowSlot2: [],
      SlotNum: [],
      countRowSlot: "",
      localDataBranch: [], //เป็น-array Id
      localData: [],
      dataROFinish: [],
      dataRoFinishId: [],

      colSp: 4,
      fslot1: false,

      Col1: false,
      // end

      //Modal Add RO
      Sv: "",
      rowRo: [],
      Ro: [],
      roDate: "",
      rowUserTM: [],
      priMechanicId: "",
      loadingU: false,
      //ends modal RO
    };
  },
  computed: {},

  created() {
    this.getLocalData();
    this.getNow();
  },

  methods: {
    customLabelSv({ cusName, licensePlate }) {
      return `[${licensePlate}] — ${cusName}`;
    },
    customLabel({ name, familyName }) {
      return `${name}  ${familyName}`;
    },
    postRepair() {
      useNetw
        .post(
          "api/service/repair/store",

          {
            svId: this.Sv.svId,
            roId: this.Ro,
            priMechanicId: JSON.stringify(this.priMechanicId.userId),
            pslotId: this.pslotId,
            fslotId: this.fslotId,
          }
        )
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs.modalPostVm.hide();
          this.getDataFlowSlot();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;

          //   this.getRoWage();
          // this.getRoPart();
          // this.$router.push({ name: "branch-wages" });
        });
    },
    getUser: function() {
      // this.overlayFlag = true;
      this.loadingU = true;
      useNetw
        .get("api/user", {
          params: {
            page: 1,
            perPage: 100,
            branchId: [this.branchIdLC],
          },
        })
        .then((response) => {
          this.rowUserTM = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loadingU = false; //skeleton false
        });
    },

    getDataRO() {
      // // console.log(this.Sv.svId);
      useNetw
        .get("api/service/ro", {
          params: {
            page: 1,
            perPage: 100,
            svId: this.Sv.svId,
          },
        })
        .then((response) => {
          this.Ro = [];
          this.rowRo = response.data.data;
          // // console.log('RO ===>' ,this.rowRo);

          // // console.log("77777", response);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    getDataService: function() {
      this.isLoading = true;
      useNetw
        .get("api/service", {
          params: {
            page: 1,
            perPage: 100,
            // vin: this.vin.vin,
            branchId: [this.branchIdLC],
          },
        })
        .then((response) => {
          this.rowsService = response.data.data;
          // console.log("Customer", this.rowsService);

          // this.rowsSearch = {
          //   vin: response.data.data.vin,
          //   lisencePlate: response.data.data.lisencePlate,
          // };
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    popUpmodalPostVm(fslot, itemSlotNum) {
      this.fslotId = fslot;
      this.pslotId = itemSlotNum;
      this.roDate = this.DateSearch;

      this.$refs["modalPostVm"].show();
    },

    changeStatusFL(statusVm) {
      if (statusVm == "SW") {
        return "กำลังล้าง";
      } else if (statusVm == "CIN") {
        return "เข้าจอด";
      } else if (statusVm == "COUT") {
        return "เสร็จสิ้น";
      } else if (statusVm == "FW") {
        return "ล้างเสร็จ";
      } else if (statusVm == "FIN") {
        return "นำรถออกจากโรงซ่อมแล้ว";
      }
    },

    colorChangeClass(itemVM) {
      return {
        "bg-danger text-center text-white rounded": itemVM == "CIN",
        "bg-primary text-center text-white rounded": itemVM == "SW",
        "bg-success text-center text-white rounded ": itemVM == "FW",
        "bg-secondary text-center text-white rounded": itemVM == "COUT",
        "bg-dark text-center text-white rounded": itemVM == "FIN",
      };
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchIdLC = user.branchId;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
      // // console.log(this.localDataBranch);
    },

    getDataFlowSlot: function() {
      this.loading = true;
      useNetw
        .get("api/flow-slot", {
          params: {
            branchId: this.branchId.branchId,
            date: this.DateSearch,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowSlot = response.data.data;
          // console.log("flow-Slot ====== > ", this.rowSlot);
          this.getDatParking();
          this.getDataService();
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDatParking: function() {
      this.loading = true;
      useNetw
        .get("api/master/gs-parking-slots", {
          params: {
            branchId: this.branchId.branchId,
            date: this.DateSearch,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.SlotNum = response.data.data;
          // // console.log("SlotNum ====== > ", this.SlotNum);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getNow: function() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();

      this.DateSearch = date;
      // // console.log(this.DateSearch);
    },
    popupModalUpdateFslot(itemVM) {
      // console.log("itemVM in popupModal=>", itemVM);

      this.itemVMP = itemVM;
      this.svIdP = itemVM.svId;
      this.repairIdP = itemVM.repairId;

      if (itemVM.repairStatus != "FIN") {
        this.$refs["modalUpdateFslot"].show();
      }

      // this.repairStatus = this.itemVMP.repairStatus
    },
    closemodalUpdateFslot() {
      this.$refs["modalUpdateFslot"].hide();
      this.repairStatus = "";
    },
    getDataROFinish(svId) {
      useNetw
        .get("api/service/ro", {
          params: {
            page: 1,
            perPage: 100,
            svId: svId,
          },
        })
        .then((response) => {
          this.dataROFinish = response.data.data;
          const idRO = [];
          let totalPriceRO = 0;
          this.dataROFinish.forEach((itemRO) => {
            idRO.push(itemRO.roId);
            totalPriceRO += parseFloat(itemRO.grandTotalPrice);
          });
          this.dataRoFinishId = idRO;
          this.totalPriceRO = totalPriceRO;
          // console.log("ttp of RO =>", this.totalPriceRO);
          // console.log("Id od Sv RO =>", this.dataRoFinishId);
          // console.log("dataRO =>", this.dataROFinish);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    popupFinishRO() {
      this.$refs["modalUpdateFslot"].hide();

      this.$refs["modalFinishRO"].show();

      this.getDataROFinish(this.itemVMP.svId);
    },

    finishRO: function() {
      this.loading = true;
      useNetw
        .put("api/service/repair/finish", {
          svId: this.svIdP,
          repairId: this.repairIdP,
          roId: this.dataRoFinishId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs["modalFinishRO"].hide();
          this.dataROFinish = [];
          (this.dataRoFinishId = []), this.getDataFlowSlot();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.validationMessage),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    putStatusVm: function() {
      this.loading = true;
      useNetw
        .put("api/service/repair/update", {
          svId: this.svIdP,
          repairId: this.repairIdP,
          status: this.repairStatus.repairStatusID,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.closemodalUpdateFslot();
          this.getDataFlowSlot();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.validationMessage),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getReportFlowSlot() {
      this.loading = true;
      useNetw
        .get("api/flow-slot/print-flow-slot", {
          params: {
            branchId: this.branchId.branchId,
            date: this.DateSearch,
          },
        }) // ?เอามาจากไหน
        .then((data) => {
          // // console.log(data.data.url);
          this.linkPtintFlowslot = data.data.url;
          // window.open(data.data.url, '_blank');
          window.location = (data.data.url);
          // this.$refs["modalPrintflowSlot"].show();
          // window.open = data.data.url;
          // this.loading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("ไม่พบข้อมูลรถในวันที่ " + this.DateSearch),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton-table
                  :rows="5"
                  :columns="6"
                  :table-props="{ bordered: false, striped: true }"
                  animation="throb"
                ></b-skeleton-table>
              </template>
              <div class="row">
                <div class="col-md-12 text-end">
                  <router-link :to="{ name: 'home' }">
                    <a class="btn btn-secondary">กลับสู่หน้าหลัก</a>
                  </router-link>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-5">
                  <div class="mb-3 position-relative">
                    <code> * </code>
                    <label for="branchId">สาขา</label><br />
                    <multiselect
                      v-model="branchId"
                      :options="localData"
                      label="nameTh"
                    >
                    </multiselect>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="mb-3 position-relative">
                    <code> * </code>
                    <label>วันที่</label>

                    <date-picker
                      v-model="DateSearch"
                      :first-day-of-week="1"
                      format="YYYY-MM-DD"
                      value-type="format"
                      lang="en"
                    >
                    </date-picker>
                  </div>
                </div>

                <div class="col-md-4 text-end">
                  <label for="Search">&nbsp;</label><br />
                  <btnComponent
                    @click="getDataFlowSlot()"
                    changeStyle="search"
                  ></btnComponent>
                  &nbsp;
                  <button class="btn btn-primary" @click="getReportFlowSlot()">
                    <i class="uil-print"></i>
                    พิมพ์รายงาน
                  </button>
                </div>
              </div>

              <hr />

              <div class="table-responsive">
                <table class="table mb-0 table-bordered">
                  <thead>
                    <tr>
                      <th rowspan="8" class="text-center">ช่องบริการ / เวลา</th>
                      <th colspan="8" class="text-center">SlotTime 1</th>
                      <th colspan="8" class="text-center">SlotTime 2</th>
                      <th colspan="4" class="text-center">-</th>
                      <th colspan="8" class="text-center">SlotTime 3</th>
                      <th colspan="12" class="text-center">SlotTime 4</th>
                    </tr>
                    <tr>
                      <th :colspan="colSp" class="text-center">08.00</th>
                      <th :colspan="colSp" class="text-center">09.00</th>
                      <th :colspan="colSp" class="text-center">10.00</th>
                      <th :colspan="colSp" class="text-center">11.00</th>
                      <th :colspan="colSp" class="text-center">12.00</th>
                      <th :colspan="colSp" class="text-center">13.00</th>
                      <th :colspan="colSp" class="text-center">14.00</th>
                      <th :colspan="colSp" class="text-center">15.00</th>
                      <th :colspan="colSp" class="text-center">16.00</th>
                      <!-- <th :colspan="colSp" class="text-center">17.00</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(itemSlotNum, index) in SlotNum" :key="index">
                      <th class="text-center">{{ itemSlotNum.nameTh }}</th>

                      <td colspan="8">
                        <div
                          v-for="(itemFL, index) in rowSlot"
                          :key="'FL' + index"
                        >
                          <div
                            v-if="
                              itemFL.pslotId === itemSlotNum.pslotId &&
                                itemFL.vehicles.length > 0
                            "
                          >
                            <div
                              v-for="(itemVM, index) in itemFL.vehicles"
                              :key="'VM' + index"
                            >
                              <div
                                v-bind:class="
                                  colorChangeClass(itemVM.repairStatus)
                                "
                                v-if="itemVM.fslotId === 1"
                                class="text-center"
                                @click="popupModalUpdateFslot(itemVM)"
                              >
                                {{ itemVM.licensePlate }} <br />
                                (ช่าง : {{ itemVM.mechName }}) <br />
                                ชื่อลูกค้า : คุณ{{ itemVM.customerNameTh }}
                                <br />
                                สถานะ :
                                {{ changeStatusFL(itemVM.repairStatus) }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="
                            rowSlot.filter(
                              (itemFL) =>
                                itemFL.vehicles.fslotId === 1 &&
                                itemFL.pslotId === itemSlotNum.pslotId
                            ).length < 1
                          "
                        >
                          <!-- <a  class="mx-auto mb-auto">เพิ่มการนัดหมาย</a> -->
                          <b-button
                            size="sm"
                            variant="outline-secondary"
                            class="mb-2 w-100"
                            @click="popUpmodalPostVm('1', itemSlotNum.pslotId)"
                          >
                            + Workshop
                          </b-button>
                        </div>
                      </td>

                      <td colspan="8">
                        <div
                          v-for="(itemFL, index) in rowSlot"
                          :key="'FL' + index"
                        >
                          <div
                            v-if="
                              itemFL.pslotId === itemSlotNum.pslotId &&
                                itemFL.vehicles.length > 0
                            "
                          >
                            <div
                              v-for="(itemVM, index) in itemFL.vehicles"
                              :key="'VM' + index"
                            >
                              <div
                                v-bind:class="
                                  colorChangeClass(itemVM.repairStatus)
                                "
                                v-if="itemVM.fslotId === 2"
                                class="text-center"
                                @click="popupModalUpdateFslot(itemVM)"
                              >
                                {{ itemVM.licensePlate }} <br />
                                (ช่าง : {{ itemVM.mechName }}) <br />
                                ชื่อลูกค้า : คุณ{{ itemVM.customerNameTh }}
                                <br />
                                สถานะ :
                                {{ changeStatusFL(itemVM.repairStatus) }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="
                            rowSlot.filter(
                              (itemFL) =>
                                itemFL.vehicles.fslotId === 2 &&
                                itemFL.pslotId === itemSlotNum.pslotId
                            ).length < 1
                          "
                        >
                          <!-- <a  class="mx-auto mb-auto">เพิ่มการนัดหมาย</a> -->
                          <b-button
                            size="sm"
                            variant="outline-secondary"
                            class="mb-2 w-100"
                            @click="popUpmodalPostVm('2', itemSlotNum.pslotId)"
                          >
                            + Workshop
                          </b-button>
                        </div>
                      </td>

                      <td colspan="4" class="text-center">-</td>

                      <td colspan="8">
                        <div
                          v-for="(itemFL, index) in rowSlot"
                          :key="'FL' + index"
                        >
                          <div
                            v-if="
                              itemFL.pslotId === itemSlotNum.pslotId &&
                                itemFL.vehicles.length > 0
                            "
                          >
                            <div
                              v-for="(itemVM, index) in itemFL.vehicles"
                              :key="'VM' + index"
                            >
                              <div
                                v-bind:class="
                                  colorChangeClass(itemVM.repairStatus)
                                "
                                v-if="itemVM.fslotId === 3"
                                class="text-center"
                                @click="popupModalUpdateFslot(itemVM)"
                              >
                                {{ itemVM.licensePlate }} <br />
                                (ช่าง : {{ itemVM.mechName }}) <br />
                                ชื่อลูกค้า : คุณ{{ itemVM.customerNameTh }}
                                <br />
                                สถานะ :
                                {{ changeStatusFL(itemVM.repairStatus) }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="
                            rowSlot.filter(
                              (itemFL) =>
                                itemFL.vehicles.fslotId === 3 &&
                                itemFL.pslotId === itemSlotNum.pslotId
                            ).length < 1
                          "
                        >
                          <!-- <a  class="mx-auto mb-auto">เพิ่มการนัดหมาย</a> -->
                          <b-button
                            size="sm"
                            variant="outline-secondary"
                            class="mb-2 w-100"
                            @click="popUpmodalPostVm('3', itemSlotNum.pslotId)"
                          >
                            + Workshop
                          </b-button>
                        </div>
                      </td>

                      <td colspan="8">
                        <div
                          v-for="(itemFL, index) in rowSlot"
                          :key="'FL' + index"
                        >
                          <div
                            v-if="
                              itemFL.pslotId === itemSlotNum.pslotId &&
                                itemFL.vehicles.length > 0
                            "
                          >
                            <div
                              v-for="(itemVM, index) in itemFL.vehicles"
                              :key="'VM' + index"
                            >
                              <div
                                v-bind:class="
                                  colorChangeClass(itemVM.repairStatus)
                                "
                                v-if="itemVM.fslotId === 4"
                                @click="popupModalUpdateFslot(itemVM)"
                              >
                                {{ itemVM.licensePlate }} <br />
                                (ช่าง : {{ itemVM.mechName }}) <br />
                                ชื่อลูกค้า : คุณ{{ itemVM.customerNameTh }}
                                <br />
                                สถานะ :
                                {{ changeStatusFL(itemVM.repairStatus) }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="
                            rowSlot.filter(
                              (itemFL) =>
                                itemFL.vehicles.fslotId === 4 &&
                                itemFL.pslotId === itemSlotNum.pslotId
                            ).length < 1
                          "
                        >
                          <!-- <a  class="mx-auto mb-auto">เพิ่มการนัดหมาย</a> -->
                          <b-button
                            size="sm"
                            variant="outline-secondary"
                            class="mb-2 w-100"
                            @click="popUpmodalPostVm('4', itemSlotNum.pslotId)"
                          >
                            + Workshop
                          </b-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="modalUpdateFslot" hide-footer title="อัพเดทสถานะการซ่อม">
      <center>
        <div class="row">
          <h2>หมายเลขทะเบียน : {{ itemVMP.licensePlate }}</h2>
          <h2>
            สถานะ :
          </h2>
        </div>

        <div class="row">
          <div class="col-md-10">
            <multiselect
              v-model="repairStatus"
              :options="optionsStatusFslot"
              label="nameTh"
            >
            </multiselect>
          </div>
          <div class="col-md-2">
            <button class="btn btn-success" @click="putStatusVm()">
              อัพเดท
            </button>
          </div>
        </div>
      </center>
      <hr />
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-warning w-100" @click="popupFinishRO()">
            นำรถออกจากโรงซ่อม
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal size="xl" ref="modalPostVm" hide-footer title="นำรถเข้าซ่อม">
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 position-relative">
            <code> * </code>
            <label for="branchId">ลูกค้า</label><br />
            <multiselect
              v-model="Sv"
              :options="rowsService"
              :custom-label="customLabelSv"
              label="cusName"
              @input="getDataRO()"
            >
            </multiselect>
          </div>
        </div>

        <div class="col-6 col-sm-3 col-md-3">
          <div class="mb-3 position-relative">
            <code>* </code><label for="validation">วันที่เข้ารับบริการ</label>
            <date-picker
              v-model="roDate"
              format="YYYY-MM-DD"
              value-type="format"
            ></date-picker>
          </div>
        </div>

        <div class="col-md-3 col-sm-3">
          <div class="mb-3 position-relative">
            <label for="priMechanicId">ช่างที่รับผิดชอบ</label>
            <multiselect
              id="priMechanicId"
              type="search"
              v-model="priMechanicId"
              :options="rowUserTM"
              :custom-label="customLabel"
              :loading="loadingU"
              :close-on-select="true"
              :internal-search="true"
              open-direction="bottom"
              @search-change="getUser"
              placeholder=""
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}&nbsp;</span
                  ><span class="option__small">{{
                    props.option.familyName
                  }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="row">
        <code> * </code><label for="branchId">Repait Order</label><br />
        <div
          class="col-md-3"
          v-for="(itemRO, index) in rowRo"
          :key="'RO' + index"
        >
          <input :value="itemRO.roId" type="checkbox" v-model="Ro" /> &nbsp;{{
            itemRO.roCode
          }}
        </div>
      </div>

      <hr />
      <div class="text-end">
        <button class="btn btn-success" @click="postRepair()">เพิ่ม</button>
      </div>
    </b-modal>
    <b-modal
      title="เอกสารรายงานรถเข้าซ่อม"
      hide-footer
      size="sm"
      centered
      ref="modalPrintflowSlot"
    >
      <div class="row">
        <div class="col">
          <label for="amount"
            ><code>*</code> วันที่ : {{ DateSearch }} <br />
            {{ branchId.nameTh }}
          </label>
          <hr />
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <a class="btn btn-primary" :href="this.linkPtintFlowslot">
              Print
            </a>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      title="นำรถออกจากโรงซ่อม"
      hide-footer
      size="xl"
      centered
      ref="modalFinishRO"
    >
      <div class="row">
        <div class="col">
          <b>
            เลขทะเบียน : {{ itemVMP.licensePlate }} <br />
            ชื่อลูกค้า : คุณ{{ itemVMP.customerNameTh }}
            {{ itemVMP.customerFamilyNameTh }} <br />
            <br />
            ข้อมูลใบแจ้งซ่อม :</b
          >
          <br />
          <table class="table">
            <thead>
              <tr>
                <th>ลำดับ</th>
                <th>เลขใบแจ้งซ่อม</th>
                <th>ประเภท</th>
                <th>ยอดสุทธิ</th>
                <!-- <th>roId</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(itemRO, indexRO) in dataROFinish" :key="indexRO">
                <td>{{ indexRO + 1 }}</td>
                <td>{{ itemRO.roCode }}</td>
                <td>{{ itemRO.roType }}</td>
                <td>{{ itemRO.grandTotalPrice }}</td>
                <!-- <td>{{itemRO.roId}}</td> -->
              </tr>
            </tbody>
          </table>
          <br />

          <h3>
            รวมยอดสุทธิ
            <span class="badge bg-dark ms-2"
              >{{ totalPriceRO }}
            </span>
          </h3>

          <hr />
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <a class="btn btn-success" @click="finishRO()">
              ยืนยันการนำรถออกจากโรงซ่อม
            </a>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
